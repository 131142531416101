import moment from 'moment-timezone'
import { Constants } from 'galarm-config'
import { I18n } from 'galarm-config'
import { GlobalConfig } from 'galarm-config'
import { LogUtils } from 'galarm-ps-api'
import * as RNLocalize from 'react-native-localize'
import { Platform } from 'react-native'

const DateTimeUtils = (function () {
  const monthNames = [
    I18n.t('january'),
    I18n.t('february'),
    I18n.t('march'),
    I18n.t('april'),
    I18n.t('may'),
    I18n.t('june'),
    I18n.t('july'),
    I18n.t('august'),
    I18n.t('september'),
    I18n.t('october'),
    I18n.t('november'),
    I18n.t('december')
  ]
  const monthNamesShort = [
    I18n.t('jan'),
    I18n.t('feb'),
    I18n.t('mar'),
    I18n.t('apr'),
    I18n.t('shortMay'),
    I18n.t('jun'),
    I18n.t('jul'),
    I18n.t('aug'),
    I18n.t('sep'),
    I18n.t('oct'),
    I18n.t('nov'),
    I18n.t('dec')
  ]
  const weekdays = [
    I18n.t('sunday'),
    I18n.t('monday'),
    I18n.t('tuesday'),
    I18n.t('wednesday'),
    I18n.t('thursday'),
    I18n.t('friday'),
    I18n.t('saturday')
  ]
  const shortWeekdays = [
    I18n.t('sun'),
    I18n.t('mon'),
    I18n.t('tue'),
    I18n.t('wed'),
    I18n.t('thu'),
    I18n.t('fri'),
    I18n.t('sat')
  ]

  const dayOfWeekInMonthOrdinal = [
    I18n.t('first'),
    I18n.t('second'),
    I18n.t('third'),
    I18n.t('fourth'),
    I18n.t('fifth')
  ]

  // {
  //   weekday: 'narrow' | 'short' | 'long',
  //   era: 'narrow' | 'short' | 'long',
  //   year: 'numeric' | '2-digit',
  //   month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
  //   day: 'numeric' | '2-digit',
  //   hour: 'numeric' | '2-digit',
  //   minute: 'numeric' | '2-digit',
  //   second: 'numeric' | '2-digit',
  //   timeZoneName: 'short' | 'long',

  //   // Time zone to express it in
  //   timeZone: 'Asia/Shanghai',
  //   // Force 12-hour or 24-hour
  //   hour12: true | false,

  //   // Rarely-used options
  //   hourCycle: 'h11' | 'h12' | 'h23' | 'h24',
  //   formatMatcher: 'basic' | 'best fit'
  // }

  const getLocalizedDateFormatString = function (locale, formatObj, date) {
    let formattedString
    try {
      formattedString = new Intl.DateTimeFormat(locale, formatObj).format(date)
    } catch (error) {
      LogUtils.logError(
        error,
        'Error while creating formatted date string for timezone ' +
          JSON.stringify(formatObj)
      )

      // Fallback to local timezone if an error occurs
      const localTimeZone = RNLocalize.getTimeZone()
      formattedString = new Intl.DateTimeFormat(locale, {
        ...formatObj,
        timeZone: localTimeZone
      }).format(date)
    }

    return formattedString
  }

  // Get localized Date as month
  const getLocalizedDateAsMonth = function (date) {
    if (Platform.OS === 'android' && Platform.Version <= 23) {
      return getDateAsMonth(date)
    }
    const locale = I18n.currentLocale()
    const string = new Intl.DateTimeFormat(locale, {
      month: 'short'
    }).format(date)

    return string
  }

  const getLocalizedTimeAsString = function (date) {
    if (Platform.OS === 'android' && Platform.Version <= 23) {
      return getTimeAsString(date)
    }
    const locale = I18n.currentLocale()
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    const string = new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
    }).format(date)

    return string
  }

  const getLocalizedTimeAsStringWithTimezone = function (date, timezone) {
    if (Platform.OS === 'android' && Platform.Version <= 23) {
      return getTimeAsStringWithTimezone(date, timezone)
    }
    const locale = I18n.currentLocale()
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    const timeString = getLocalizedDateFormatString(
      locale,
      {
        hour: 'numeric',
        minute: 'numeric',
        hour12: timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT,
        timeZone: timezone
      },
      date
    )
    const timezoneString = convertLongTimezoneNameToShort(date, timezone)
    const string = timeString + ' ' + timezoneString

    return string
  }

  const getLocalizedDayDateAsString = function (date) {
    if (Platform.OS === 'android' && Platform.Version <= 23) {
      return getDayDateAsString(date)
    }
    const locale = I18n.currentLocale()
    const string = new Intl.DateTimeFormat(locale, {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
      weekday: 'short'
    }).format(date)

    return string
  }

  const getLocalizedDateTimeWoYearAsString = function (date) {
    if (Platform.OS === 'android' && Platform.Version <= 23) {
      return getDateTimeWoYearAsString(date)
    }
    const locale = I18n.currentLocale()
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat

    const string = new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
    }).format(date)

    return string
  }

  const getLocalizedDayDateWoYearAsString = function (date) {
    if (Platform.OS === 'android' && Platform.Version <= 23) {
      return getDayDateWoYearAsString(date)
    }
    const locale = I18n.currentLocale()
    const string = new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric',
      weekday: 'short'
    }).format(date)

    return string
  }

  const getLocalizedDayDateWithYearForLaterDatesAsString = function (date) {
    // If the date falls in current year, return the date without year
    if (moment(date).year() === moment().year()) {
      return getLocalizedDayDateWoYearAsString(date)
    } else {
      return getLocalizedDayDateAsString(date)
    }
  }

  const getLocalizedDateTimeWoYearAsStringForTimezone = function (
    date,
    timezone
  ) {
    if (Platform.OS === 'android' && Platform.Version <= 23) {
      return getDateTimeWoYearAsStringWithTimezone(date, timezone)
    }
    const locale = I18n.currentLocale()
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat

    const formattedString = getLocalizedDateFormatString(
      locale,
      {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT,
        timeZone: timezone
      },
      date
    )

    const timezoneString = convertLongTimezoneNameToShort(date, timezone)
    const string = formattedString + ' ' + timezoneString

    return string
  }

  const getLocalizedDayDateAsStringForTimezone = function (date, timezone) {
    if (Platform.OS === 'android' && Platform.Version <= 23) {
      return getDayDateAsStringForTimezone(date, timezone)
    }
    const locale = I18n.currentLocale()
    const formattedString = getLocalizedDateFormatString(
      locale,
      {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
        weekday: 'short',
        timeZone: timezone
      },
      date
    )

    return formattedString
  }

  // Convert a date(ms) to a string of format 2013-02-08 09:30
  const getFormattedDateAsString = function (date) {
    return moment(date).format('YYYY-MM-DD HH:mm')
  }

  const getFormattedDateAsStringInTimezone = function (date, timezone) {
    return moment.tz(date, timezone).format('YYYY-MM-DD HH:mm')
  }

  // Reads a date string of format 2013-02-08 09:30 and converts it into a date(ms)
  const getDateFromFormattedDateString = function (dateString, timezone) {
    const momenDate = moment.tz(dateString, timezone)
    if (momenDate.isValid()) {
      return momenDate.valueOf()
    }
    return undefined
  }

  // Change the format for this as well
  const getDateTimeAsString = function (date) {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    let format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'D MMM YYYY, h:mm A'
        : 'D MMM YYYY, H:mm'
    return moment(date).format(format)
  }

  const getDateTimeAsStringInTimezone = function (date, timezone) {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    let format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'D MMM YYYY, h:mm A'
        : 'D MMM YYYY, H:mm'
    return moment.tz(date, timezone).format(format)
  }

  // Change the format for this as well
  const getDateTimeAsStringWithTimezone = function (date, timezone) {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    let format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'D MMM YYYY, h:mm A z'
        : 'D MMM YYYY, H:mm z'
    return moment.tz(date, timezone).format(format)
  }

  const convertLongTimezoneNameToShort = function (date, timezone) {
    const formattedZone = moment.tz(date, timezone).format('z')
    if (formattedZone.startsWith('+') || formattedZone.startsWith('-')) {
      return ' GMT' + formattedZone
    }
    return formattedZone
  }

  const getTimeAsStringForTimezone = function (date, timezone) {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    let format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'h:mm A'
        : 'H:mm'
    return moment.tz(date, timezone).format(format)
  }

  const getDateAsStringForTimezone = function (date, timezone) {
    return moment.tz(date, timezone).format('D MMM YYYY')
  }

  const getDayDateAsStringForTimezone = function (date, timezone) {
    return moment.tz(date, timezone).format('ddd, D MMM YYYY')
  }

  const getDayDateAsString = function (date) {
    return moment(date).format('ddd, D MMM YYYY')
  }

  const getDayDateWoYearAsString = function (date) {
    return moment(date).format('ddd, D MMM')
  }

  const getTimeAsStringWithTimezone = function (date, timezone) {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    let format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'h:mm A z'
        : 'H:mm z'
    return moment.tz(date, timezone).format(format)
  }

  const getDateTimeWoYearAsStringWithTimezone = function (date, timezone) {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    let format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'D MMM, h:mm A'
        : 'D MMM, H:mm'
    return moment(date, timezone).format(format)
  }

  const getDateTimeWoYearAsString = function (date) {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    let format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'D MMM, h:mm A'
        : 'D MMM, H:mm'
    return moment(date).format(format)
  }

  const getTimeAsString = function (date) {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    const format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'h:mm A'
        : 'H:mm'
    return moment(date).format(format)
  }

  const getDateAsMonth = function (date) {
    return moment(date).format('MMM')
  }

  const getDateAsString = function (date) {
    return moment(date).format('D MMM YYYY')
  }

  const getDateFromDateString = function (dateString) {
    return moment(dateString, 'D MMM YYYY').valueOf()
  }

  const getDayOfWeekInMonthOrdinal = function (dayOfWeekNumberInMonth) {
    return dayOfWeekInMonthOrdinal[dayOfWeekNumberInMonth - 1]
  }

  const getMyAlarmDate = function (alarmDate) {
    return alarmDate
  }

  const getParticipantAlarmDate = function (alarm, participantOrder = 0) {
    if (!alarm.date) {
      return alarm.date
    }
    if (alarm.type === Constants.AlarmTypes.CASCADING) {
      return (
        alarm.date +
        participantOrder *
          Constants.CascadingAlarmIntervals[alarm.cascadingAlarmInterval].value
      )
    } else if (alarm.type === Constants.AlarmTypes.RECIPIENT) {
      return (
        alarm.date -
        Constants.RecipientAlarmIntervals[alarm.recipientAlarmInterval].value
      )
    }
    return alarm.date
  }

  const getDayOfWeekForDateAsString = function (date) {
    return weekdays[moment(date).day()]
  }

  const getShortDayOfWeekForDateAsString = function (date) {
    return shortWeekdays[moment(date).day()]
  }

  const getDurationAsMinutesAndSecondsString = function (durationInMsecs) {
    const duration = moment.duration(durationInMsecs)
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    let durationString = ''
    if (durationInMsecs === 0) {
      durationString = I18n.t('unknown')
    } else if (durationInMsecs >= Constants.MSEC_IN_HOUR) {
      durationString = I18n.t('longerThanAnHour')
    } else {
      // if (minutes.toString().length < 2) {
      //   minutesString = '0' + minutes.toString()
      // }
      // if (seconds.toString().length < 2) {
      //   secondsString = '0' + seconds.toString()
      // }
      durationString =
        (minutes !== 0
          ? I18n.t('minutesSmall', { count: minutes }) + ' '
          : '') + I18n.t('secondsSmall', { count: seconds })
    }

    return durationString
  }

  const getDurationAsString = function (
    durationInMsecs,
    emptyDurationString = I18n.t('lessThanMinute')
  ) {
    const duration = moment.duration(durationInMsecs)
    const years = duration.years()
    const months = duration.months()
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const durationString =
      '' +
      (years > 0 ? I18n.t('numYears', { count: years }) + ' ' : '') +
      (months > 0 ? I18n.t('numMonths', { count: months }) + ' ' : '') +
      (days > 0 ? I18n.t('numDays', { count: days }) + ' ' : '') +
      (hours > 0 ? I18n.t('hours', { count: hours }) + ' ' : '') +
      (minutes > 0 ? I18n.t('minutes', { count: minutes }) + ' ' : '')
    return durationString !== '' ? durationString.trim() : emptyDurationString
  }

  const getDurationTillDateAsString = function (date) {
    const timeRemaining = Math.abs(date - Date.now())
    return getDurationAsString(timeRemaining)
  }

  const getShortDurationTillDateAsString = function (date) {
    const timeRemaining = Math.abs(date - Date.now())
    return getShortDurationAsString(timeRemaining)
  }

  const getShortDurationAsString = function (durationInMsecs) {
    const duration = moment.duration(durationInMsecs)
    const years = duration.years()
    const months = duration.months()
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()

    if (years) {
      return I18n.t('numYears', { count: years })
    } else if (months) {
      return I18n.t('numMonths', { count: months })
    } else if (days) {
      return I18n.t('numDays', { count: days })
    } else if (hours) {
      return I18n.t('hours', { count: hours })
    } else if (minutes) {
      return I18n.t('minutes', { count: minutes })
    } else {
      return I18n.t('fewSeconds')
    }
  }

  const isDateBeforeToday = function (date) {
    const dateYear = moment(date).year()
    const dateDayOfYear = moment(date).dayOfYear()
    const todayYear = moment().year()
    const todayDayOfYear = moment().dayOfYear()

    if (dateYear > todayYear || dateDayOfYear >= todayDayOfYear) {
      return false
    }

    return true
  }

  const isDateToday = function (date) {
    const dateYear = moment(date).year()
    const dateDayOfYear = moment(date).dayOfYear()
    const todayYear = moment().year()
    const todayDayOfYear = moment().dayOfYear()

    if (dateYear === todayYear && dateDayOfYear === todayDayOfYear) {
      return true
    }

    return false
  }

  const isDateTomorrow = function (date) {
    const dateYear = moment(date).year()
    const dateDayOfYear = moment(date).dayOfYear()
    const tomorrow = moment().add(1, 'days')
    const tomorrowYear = tomorrow.year()
    const tomorrowDayOfYear = tomorrow.dayOfYear()

    if (dateYear === tomorrowYear && dateDayOfYear === tomorrowDayOfYear) {
      return true
    }

    return false
  }

  const getNextOccurrenceOfTime = function (date) {
    const momentDate = moment(date)
    const hours = momentDate.hours()
    const minutes = momentDate.minutes()
    const nextDate = moment()
    nextDate.hours(hours)
    nextDate.minutes(minutes)
    nextDate.seconds(0)
    nextDate.milliseconds(0)
    if (moment().isAfter(nextDate)) {
      nextDate.add(1, 'days')
    }
    return nextDate.valueOf()
  }

  const differenceInDayFromGmt = function (date) {
    const momentDate = moment(date)
    const day = momentDate.day()
    const gmtMomentDate = moment.tz(date, Constants.GMT_TIMEZONE_NAME)
    const gmtDay = gmtMomentDate.day()
    const timezoneOffset = new Date().getTimezoneOffset()
    if (gmtDay === day) {
      return 0
    } else if (timezoneOffset > 0) {
      return 1
    } else {
      return -1
    }
  }

  const differenceInDayFromTimezone = function (date, timezone) {
    const momentDate = moment(date)
    const day = momentDate.day()
    const dateInTimezone = moment.tz(date, timezone)
    const dayInTimezone = dateInTimezone.day()
    const diffInDays = day - dayInTimezone
    if (diffInDays === 0 || Math.abs(diffInDays) === 1) {
      return diffInDays
    } else {
      if (diffInDays > 1) {
        return -1
      } else {
        return 1
      }
    }
  }

  const differenceInDaysFromTimezones = function (
    date1,
    timezone1,
    date2,
    timezone2
  ) {
    const dateInTimezone1 = moment.tz(date1, timezone1)
    const dayInTimezone1 = dateInTimezone1.day()

    const dateInTimezone2 = moment.tz(date2, timezone2)
    const dayInTimezone2 = dateInTimezone2.day()

    const diffInDays = dayInTimezone2 - dayInTimezone1
    if (diffInDays === 0 || Math.abs(diffInDays) === 1) {
      return diffInDays
    } else {
      if (diffInDays > 1) {
        return -1
      } else {
        return 1
      }
    }
  }

  const differenceInDayForDates = function (date1, date2) {
    const momentDate1 = moment(date1)
    const day1 = momentDate1.day()
    const momentDate2 = moment(date2)
    const day2 = momentDate2.day()
    if (day2 === day1) {
      return 0
    } else if (date2 > date1) {
      return 1
    } else {
      return -1
    }
  }

  const differenceInDays = function (day1, day2) {
    const diffInDays = day1 - day2
    if (diffInDays === 0 || Math.abs(diffInDays) === 1) {
      return diffInDays
    } else {
      if (diffInDays > 1) {
        return -1
      } else {
        return 1
      }
    }
  }

  const getMsecsToNextMinute = function () {
    const currDate = Date.now()
    const msecToNextMinute =
      moment().seconds(0).milliseconds(0).add(1, 'm').valueOf() - currDate
    return msecToNextMinute
  }

  const shaveSecondsFromDate = function (date) {
    return moment(date).seconds(0).milliseconds(0).valueOf()
  }

  const roundUpToNextMin = function (date) {
    return moment(date).add(1, 'm').seconds(0).milliseconds(0).valueOf()
  }

  const roundToStartOfHour = function (date) {
    return moment(date).minutes(0).seconds(0).milliseconds(0).valueOf()
  }

  const getTimezoneOffsetForTimezone = function (timezoneString) {
    try {
      if (!timezoneString) {
        LogUtils.logError(
          new Error('Timezone string is undefined while computing offset.'),
          'Using default timezone string.'
        )
        return moment.tz.zone(RNLocalize.getTimeZone()).utcOffset(Date.now())
      }
      return moment.tz.zone(timezoneString).utcOffset(Date.now())
    } catch (e) {
      LogUtils.logError(
        new Error(
          'Error while computing offset for timezone string: ' +
            timezoneString +
            '.'
        ),
        'Using default timezone string.'
      )
      return moment.tz.zone(RNLocalize.getTimeZone()).utcOffset(Date.now())
    }
  }

  const getSecondsInCurrentTime = function () {
    const date = new Date()
    const seconds = date.getSeconds()
    const msecs = date.getMilliseconds()
    return seconds + msecs / 1000
  }

  const getSecondsSincePrevMinsInterval = function (mins) {
    const currDate = Date.now()
    let date = moment().minutes(0).seconds(0).milliseconds(0).valueOf()
    let prevDate = date
    while (date < currDate) {
      prevDate = date
      date = date + Constants.MSEC_IN_MINUTE * mins
    }
    return currDate - prevDate
  }

  // Give the start and end date of the calendar shown on screen
  // which displays 35 days
  const getCalendarMonthRangeForADate = function (date) {
    // Start day in the calendar month
    const start = moment(date)
      .date(1)
      .day(0)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)

    // End day in the calendar month. Add 1 to get to the end of the day on the calendar month
    const end = moment(date)
      .endOf('month')
      .day(6)
      .add(1, 'day')
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
    return { start: start.valueOf(), end: end.valueOf() }
  }

  // Give the start and end date of the calendar shown on screen
  // which displays 35 days
  const getCalendarWeekRangeForADate = function (date) {
    // Start day in the calendar month
    const start = moment(date)
      .day(0)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)

    // End day in the calendar month. Add 1 to get to the end of the day on the calendar month
    const end = moment(date)
      .day(7)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
    return { start: start.valueOf(), end: end.valueOf() }
  }

  const getTimeStepsForDay = stepInMs => {
    const timeSteps = []
    const startTime = '12:00 AM'
    timeSteps.push(startTime)
    let nextTime = moment(startTime, 'hh:mm A')
      .add(stepInMs, 'milliseconds')
      .format('hh:mm A')
    while (!moment(startTime, 'hh:mm A').isSame(moment(nextTime, 'hh:mm A'))) {
      timeSteps.push(nextTime)
      nextTime = moment(nextTime, 'hh:mm A')
        .add(stepInMs, 'milliseconds')
        .format('hh:mm A')
    }
    return timeSteps
  }

  const getTodayDate = () => {
    return moment().hours(0).minutes(0).seconds(0).milliseconds(0).valueOf()
  }

  const getTomorrowDate = () => {
    return moment()
      .add(1, 'days')
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .valueOf()
  }

  const getDatesForMonth = (year, month) => {
    const dates = []
    // Month in JavaScript is 0-indexed (0 for January, 11 for December)
    let date = new Date(year, month, 1)

    // Loop until the month changes
    while (date.getMonth() === month) {
      dates.push(date.toDateString())
      // Increment the date by 1 day
      date.setDate(date.getDate() + 1)
    }
  }

  const isDateInNextDays = (date, days) => {
    const nextDate = moment().add(days, 'days')
    return moment(date).isBefore(nextDate)
  }

  return {
    getParticipantAlarmDate,
    getDateTimeAsString,
    getDateAsString,
    getTimeAsString,
    getDayOfWeekForDateAsString,
    getDurationTillDateAsString,
    getShortDayOfWeekForDateAsString,
    getDateTimeWoYearAsString,
    isDateToday,
    getDurationAsString,
    getNextOccurrenceOfTime,
    differenceInDayFromGmt,
    getShortDurationTillDateAsString,
    getMyAlarmDate,
    differenceInDayForDates,
    getFormattedDateAsString,
    getDateFromFormattedDateString,
    getMsecsToNextMinute,
    shaveSecondsFromDate,
    getFormattedDateAsStringInTimezone,
    differenceInDayFromTimezone,
    differenceInDays,
    getDurationAsMinutesAndSecondsString,
    getTimezoneOffsetForTimezone,
    isDateTomorrow,
    roundUpToNextMin,
    getDateTimeAsStringWithTimezone,
    getDayOfWeekInMonthOrdinal,
    getSecondsInCurrentTime,
    getSecondsSincePrevMinsInterval,
    roundToStartOfHour,
    getCalendarMonthRangeForADate,
    isDateBeforeToday,
    getTimeStepsForDay,
    getTimeAsStringForTimezone,
    getDateTimeAsStringInTimezone,
    getTimeAsStringWithTimezone,
    getLocalizedTimeAsStringWithTimezone,
    getTodayDate,
    getTomorrowDate,
    getCalendarWeekRangeForADate,
    getLocalizedTimeAsString,
    getLocalizedDayDateAsString,
    getDateAsStringForTimezone,
    getDayDateAsStringForTimezone,
    getLocalizedDayDateAsStringForTimezone,
    getLocalizedDateTimeWoYearAsString,
    getDayDateAsString,
    getLocalizedDayDateWoYearAsString,
    getLocalizedDateAsMonth,
    weekdays,
    shortWeekdays,
    monthNames,
    monthNamesShort,
    getDatesForMonth,
    getDateFromDateString,
    getLocalizedDayDateWithYearForLaterDatesAsString,
    getLocalizedDateTimeWoYearAsStringForTimezone,
    isDateInNextDays,
    convertLongTimezoneNameToShort,
    differenceInDaysFromTimezones
  }
})()

export default DateTimeUtils
